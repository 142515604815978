<template>
  <div class='base-header flex-between' v-if="showHeader">
    <img :src="systemInfo.logo" class="logo pointer" @click="toRoute('')">
    <div class="flex pointer nav">
      <div v-for="item, i in store.state.navList" :key="item.id" @click="handleCommand(i + 1)">
        <div class="nav-item flex pointer a-center" @click="handleTitle(item)">
          <div class="nav-item-t" :class="{ 'nav-item-c': chooseIndex === i + 1 }">{{ item.title }}</div>
          <div class="box2" :class="{ 'boxRotate': chooseIndex === i + 1 }" v-if="item.isSecondLevelModular"></div>
        </div>
      </div>

    </div>

    <div class="flex btns" v-if="showHeader">
      <button class="login" v-if="!login" @click="toRoute('login')">立即登录</button>
      <el-dropdown popper-class="tel-down" v-if="login">
        <button>
          <div class="tel">{{ userInfo.user.name }}</div>
        </button>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="outLogin">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, toRefs, reactive, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore, } from 'vuex'
export default defineComponent({
  setup() {
    const data = reactive({
      chooseIndex: ''
    })

    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    watch(() => store.state.swiperIndex, (newVal) => {
      data.chooseIndex = newVal
    })
    const handleCommand = (i) => {
      if (data.chooseIndex == i) {
        data.chooseIndex = ''
      } else {
        data.chooseIndex = i
      }
      if(i===3){
        data.chooseIndex = ''
      }else{
        store.commit('SET_SWIPER_INDEX', data.chooseIndex)
      }
      
    }

    const handleTitle = (e) => {
      if (e.code === '103') {
        if (!store.state.login) {
           router.push({
            path: '/login'
          })
          store.commit('SET_SWIPER_INDEX', 0)
          return
        }

        let type = `${e.type}`
        let prefix =''
        if(type == 2){
          prefix = '/ws'
        }else if(type == 4){
          prefix = '/stu'
        }else {
          prefix = '/xk'
        }
        localStorage.setItem('typeCode',e.code)
        const routeUrl = router.resolve({
          path: `${prefix}${e.url}`,
          query:{
            code:e.code
          }
        })
        window.open(routeUrl.href, '_self');
      }

      if(e.code == '105' || e.code == '106'|| e.code == '107'){
        window.open(e.realmName, '_blank');
      }
    }

    const toRoute = (e) => {
      router.push({
        path: '/' + e
      })
    }
    const outLogin = () => {
      store.dispatch('OUT_LOGIN')
    }

    return {
      login: computed(() => {
        return store.state.login
      }),
      userInfo: computed(() => {
        return store.state.userInfo
      }),
      showHeader: computed(() => {
        return route.path !== '/login' && route.path !== '/register' && route.path !== '/findPwd' && route.path !== '/agreement'
      }),
      systemInfo:computed(() => {
        return store.state.systemInfo
      }),
      store,
      handleCommand,
      handleTitle,
      toRoute,
      outLogin,
      ...toRefs(data),
    }
  }
})
</script>
<style lang='scss' scoped>
.base-header {
  padding: 0 330px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
}


.nav {
  flex: 1;
  padding-left: 40px;

  .nav-item {
    height: 36px;
    min-width: 100px;
    line-height: 36px;
  }

  .nav-item-t {
    padding: 0 10px 0 18px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    font-size: 15px;
    font-family: MiSans, MiSans-Regular;
    font-weight: 400;
    text-align: LEFT;
    color: rgba(255, 255, 255, 0.5);
  }

  .nav-item-c {
    color: #fff;
  }
}

.box2 {
  width: 5px;
  height: 5px;
  margin-bottom: 2px;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  /* 上箭头颜色 */
  border-right: 1px solid rgba(255, 255, 255, 0.5);
  /* 右箭头颜色 */
  transform: rotate(135deg);
  /*旋转135度*/
  transition: all 0.5s ease 0s;
  /*设置动画效果  0.5秒完成动画 ease(逐渐变慢)*/
}

.boxRotate {
  border-top: 1px solid #fff;
  /* 上箭头颜色 */
  border-right: 1px solid #fff;
  transform: rotate(-45deg);
  /* 逆旋转180度 */

}

button {
  // padding: 0 10px;
  // margin: 0 40px;
  height: 76px;
  line-height: 76px;
  text-align: center;
  font-size: 15px;
  font-family: MiSans, MiSans-Regular;
  // font-weight: 400;
  // text-align: LEFT;
  color: #fff8f8;
  background: transparent;
  border: none;

  &.current {
    background: #242d4e;
  }
}

.btns {
  button {
    width: 100px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    background: linear-gradient(90deg, #fe8c00, #ff3800 100%);
    border-radius: 100px;
    font-size: 15px;
    font-family: MiSans, MiSans-Medium;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}


.tel {
  width: 98px;
  height: 34px;
  border-radius: 17px;
}

.tel-down {
  background: rgba(36, 45, 78, 1) !important;
  border-bottom: 0.5px solid rgba(36, 45, 78, 0.10) !important;
}
</style>
