const apps = [
  {
    name: 'ws',		// 子应用 package.json 中 name
    // entry: '//192.168.6.13:8000',	// 子应用的 ip 地址
    // entry: 'https://jinanbk.winshawn.net/',	// 子应用的 ip 地址
    // entry: 'https://jinanbk.winshawn.com/',	// 济南子应用的 ip 地址
    entry: '//gnez.tsuiwen.com:8000',	// 肇源子应用的 ip 地址
    container: "#mainC",
    activeRule: '/ws',		// 激活当前子应用时路径前自动拼接的路径
    sandbox: {
      strictStyleIsolation: true
    }
  },
  {
    name: 'aitest',		// 子应用 package.json 中 name
    // entry: '//192.168.6.13:8999',	// 子应用的 ip 地址
    // entry: 'https://jinanaitest.winshanwn.net/',	// 子应用的 ip 地址
    // entry: 'https://jinanaitest.winshawn.com/',	// 济南子应用的 ip 地址
    entry: '//gnez.tsuiwen.com:8999',	// z肇源子应用的 ip 地址
    container: "#mainC",
    activeRule: '/aitest',		// 激活当前子应用时路径前自动拼接的路径
    sandbox: {
      strictStyleIsolation: true
    }
  },
  {
    name: 'xk',		// 子应用 package.json 中 name
    // entry: '//192.168.6.13:9991',	// 子应用的 ip 地址
    // entry: 'https://jinanxk.winshawn.net/',	// 子应用的 ip 地址
    // entry: 'https://jinanxk.winshawn.net/',	// 济南子应用的 ip 地址
    entry: '//gnez.tsuiwen.com:9991',	// 子应用的 ip 地址
    container: "#mainC",
    activeRule: '/xk',		// 激活当前子应用时路径前自动拼接的路径
    sandbox: {
      strictStyleIsolation: true
    }
  },
  // 山东没有选科  用的学生端 肇源用的选科
  // {
  //   name: 'stu',		// 用户画像学生端 子应用 package.json 中 name
  //   // entry: '//192.168.6.13:5173',	// 子应用的 ip 地址
  //   // entry: 'https://jinansh.winshawn.net/',	// 子应用的 ip 地址
  //   entry: 'https://jinanhxxs.winshawn.com/',	// 济南子应用的 ip 地址
  //   container: "#mainC",
  //   activeRule: '/stu',		// 激活当前子应用时路径前自动拼接的路径
  //   sandbox: {
  //     strictStyleIsolation: true
  //   }
  // }
]

import {
  registerMicroApps,
  addGlobalUncaughtErrorHandler,
  start,
} from "qiankun";


registerMicroApps(apps, {
  // qiankun 生命周期钩子 - 微应用加载前
  beforeLoad: (app) => {
    // 加载微应用前，加载进度条
    // NProgress.start();
    console.log("before load", app.name);
    return Promise.resolve();
  },
  // qiankun 生命周期钩子 - 微应用挂载后
  afterMount: (app) => {
    // 加载微应用前，进度条加载完成
    // NProgress.done();
    console.log("after mount", app.name);
    return Promise.resolve();
  }
});

/**
 * 添加全局的未捕获异常处理器
 */
addGlobalUncaughtErrorHandler((event) => {
  console.error(event);
  const { message: msg } = event;
  // 加载失败时提示
  if (msg && msg.includes("died in status LOADING_SOURCE_CODE")) {
    console.error("微应用加载失败，请检查应用是否可运行");
  }
});

// 导出 qiankun 的启动函数
export default start;