<template>
  <div>
    <div v-show="$route.name" class="base-wrapper">
      <zy-header></zy-header>
      <router-view :key="$route.fullPath" v-loading.fullscreen="loading"/>
      <zy-footer></zy-footer>
    </div>
    <section id="mainC" v-show="!$route.name"/>
  </div>
</template>
<script>
import { defineComponent,onMounted,ref } from 'vue'
import zyHeader from '@/components/header.vue'
import zyFooter from '@/components/footer.vue'
import { useStore } from 'vuex'
import { useRoute } from "vue-router"
import { getLogoByUrl } from "@/api"
export default defineComponent({
  components: {
    zyHeader,
    zyFooter
  },
  setup () {
    const store = useStore()
    const loading = ref(true)
    const route = useRoute()
    console.log(route)
    onMounted(async () => {
      await store.dispatch('getNavList')
      loading.value = false
    })
    getLogoByUrl({ul:process.env.NODE_ENV=='development' ? 'https://jinan.winshawn.net/' :window.location.host}).then(res =>{
      store.commit('SET_SYSTEM_INFO',res.obj)
    })
    return route
  }
})
</script>
<style lang="scss">
.base-wrapper {
  background: url("./assets/images/BG@2x.png") no-repeat center;
  background-size: 100% 100%;
  background-attachment: fixed;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
}
.el-loading-mask {
  background-color: rgba(0,0,0,0.50)!important;
}

@import url("./assets/fonts/fonts.css");
* {
  margin: 0;
  padding: 0;
}
ul,li {
  list-style: none;
}

.pointer {
  cursor: pointer;
}
.a-center{
  align-items: center;
}
.flex {
  display: flex;
  align-items: center;
}
.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
  
}

.column{
  flex-direction: column;
}

.logo {
    width: 252px;
    max-height: 76px!important;
    padding: 8px 0;
}
</style>
